


































// Core
import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'

// Store
import {userNamespace} from '@store/user'

// Types
import {TaskActions, TaskMutations} from '@store/task/types'

// Interfaces
import {Meta, Paginate, RequestParams} from '@store/interfaces'
import {Task, TaskState} from '@store/task/interfaces'

// Modules
const NSTask = namespace('taskModule')

@Component({
  components: {
    'v-scroll-list': () => import('@components/ScrollList.vue'),
    'v-task-out': () => import('@/components/TaskOut.vue'),
    'v-task-in': () => import('@/components/TaskIn.vue'),
    'v-dialog-create-task': () => import('@components/dialogs/DialogCreateTask.vue'),
  },
})
export default class TaskList extends Vue {
  @userNamespace.State('selectedProject')
  private projectId!: number

  @NSTask.State((state: TaskState) => state.taskList)
  private tasks!: Task[]

  @NSTask.State((state: TaskState) => state.tasks.meta)
  private meta!: Meta

  @NSTask.Mutation(TaskMutations.M_CLEAR_TASKS)
  private clearTasks!: () => void

  @NSTask.Action(TaskActions.A_FETCH_TASKS)
  private fetchTasks!: (params?: RequestParams) => Promise<Paginate<Task>>

  private visibleDialogCreateTask: boolean = false
  private loading: boolean = false

  async created() {
    this.loading = true

    this.clearTasks()
    await this.fetchTasks({projectId: this.projectId})

    this.loading = false
  }

  private loadMore() {
    if (this.meta.lastPage > this.meta.currentPage) {
      this.fetchTasks({
        projectId: this.projectId,
        page: this.meta.currentPage + 1,
      })
    }
  }
}
